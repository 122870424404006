<template>
  <aside
    id="logo-sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-blue-900 border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidebar"
  >
    <div class="h-full px-3 pb-4 overflow-y-auto bg-blue-900 dark:bg-gray-800">
      <ul class="space-y-2 font-medium">
        <li
          v-if="
            userLevel == 'admin' ||
            userLevel == 'user' ||
            userLevel == 'surveyor' ||
            userLevel == 'koordinator' ||
            userLevel == 'monitor'
          "
        >
          <router-link
            to="/dashboard"
            class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-blue-100 hover:text-slate-600 dark:hover:text-white dark:hover:bg-gray-700 group"
            :class="{
              'text-slate-700 bg-blue-100 dark:text-white dark:bg-gray-700':
                link == 'dashboard',
              'text-white': link != 'dashboard',
            }"
            ><font-awesome-icon
              icon="chart-pie"
              class="w-5 h-5 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              :class="{ 'text-slate-700': link === 'dashboard' }"
            />
            <span class="ms-3">Dashboard</span>
          </router-link>
        </li>
        <li
          v-if="
            userLevel == 'admin' ||
            userLevel == 'user' ||
            userLevel == 'surveyor' ||
            userLevel == 'koordinator' ||
            userLevel == 'monitor'
          "
        >
          <router-link
            to="/survey/"
            class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-blue-100 hover:text-slate-600 dark:hover:text-white dark:hover:bg-gray-700 group"
            :class="{
              'text-slate-700 bg-blue-100 dark:text-white dark:bg-gray-700':
                link == 'survey',
              'text-white': link != 'survey',
            }"
          >
            <font-awesome-icon
              icon="file-pen"
              class="w-5 h-5 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              :class="{ 'text-slate-700': link === 'survey' }"
            />
            <span class="flex-1 ms-3 whitespace-nowrap">Survey</span>
          </router-link>
        </li>
        <li v-if="userLevel == 'admin'">
          <router-link
            to="/pengguna"
            class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-blue-100 hover:text-slate-600 dark:hover:text-white dark:hover:bg-gray-700 group"
            :class="{
              'text-slate-700 bg-blue-100 dark:text-white dark:bg-gray-700':
                link == 'pengguna',
              'text-white': link != 'pengguna',
            }"
          >
            <font-awesome-icon
              icon="user-shield"
              class="w-5 h-5 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              :class="{ 'text-slate-700': link === 'pengguna' }"
            />
            <span class="flex-1 ms-3 whitespace-nowrap">Pengguna</span>
          </router-link>
        </li>
        <li v-if="userLevel == 'user'">
          <router-link
            to="/profil"
            class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-blue-100 hover:text-slate-600 dark:hover:text-white dark:hover:bg-gray-700 group"
            :class="{
              'text-slate-700 bg-blue-100 dark:text-white dark:bg-gray-700':
                link == 'profil',
              'text-white': link != 'profil',
            }"
          >
            <font-awesome-icon
              icon="user"
              class="w-5 h-5 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              :class="{ 'text-slate-700': link === 'profil' }"
            />
            <span class="flex-1 ms-3 whitespace-nowrap">Profil</span>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "dashboard",
      isMasterMenuOpen: false,
      fullName: "User",
      userName: "User",
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.fullName = this.user.nama;
      this.userName = this.user.username;
      this.userLevel = this.user.level;
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
        console.log(this.link);
      },
    },
  },
};
</script>

